import { getContent } from '@/api'
import { locale } from '@/plugins/i18n'

export const defaultColor = '#e30713'

let pathname = window.location.pathname.split('/').filter(i => i !== '')[0]

if (pathname === 'result') {
  window.location.href = `/ru/ru/result/${window.location.search}`
}

export let region = pathname ?? 'ru'

function setMainColor(data) {
  localStorage.setItem('main-color', data.data.languages[region]?.theme.color ?? defaultColor)
}

export function makeInitialRequest() {
  return getContent(locale, region).then((data) => {
    setMainColor(data)

    return Promise.resolve({
      content: data.data
    })
  })
}
