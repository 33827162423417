<template>
  <div id="app">
    <loading
      :active.sync="computedIsLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="1"
      backgroundColor="#eeeeee"
      :z-index="5"
      is-full-page
    ></loading>
    <router-view />
    <notifications group="foo" />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import { uuid } from 'vue-uuid'
import { mapActions, mapState } from 'vuex'
const routesWithoutHeader = ['MissingPage', '404']
export default {
  name: 'app',
  data: () => ({
    sessionToken: null
  }),
  components: {
    Loading
  },
  mounted() {
    this.sessionToken = uuid.v1()
    sessionStorage.setItem('sessionToken', this.sessionToken)
    window.parent.postMessage({ auth: null }, '*')
    window.addEventListener('message', (evt) => {
      const hasAuthField = Object.keys(evt.data)?.some((field) => field === 'auth')
      const hasObjIdField = Object.keys(evt.data)?.some((field) => field === 'objId')
      const isSavingSuccessful = Object.keys(evt.data)?.some((field) => field === 'saved')

      if (hasAuthField) {
        this.addSessionId(evt.data.auth)
      }

      if (hasObjIdField) {
        this.addObjectId(evt.data.objId)
      }

      if (isSavingSuccessful) {
        this.markCalculationAsSaved(evt.data.saved)
        this.addPublicLink(evt.data.saved)
      }
    })
  },
  methods: {
    ...mapActions({
      addSessionId: 'addSessionId',
      addObjectId: 'addObjectId',
      markCalculationAsSaved: 'markCalculationAsSaved',
      addPublicLink: 'addPublicLink'
    })
  },
  computed: {
    ...mapState({
      isLoading: state => state.pages.wizard.isLoading,
      loadingStatus: state => state.pages.result.isLoading
    }),
    routeName() {
      return this.$route.name
    },
    isRouteWithoutHeader() {
      return routesWithoutHeader.includes(this.routeName)
    },
    computedIsLoading() {
      return !this.isRouteWithoutHeader && (this.isLoading || this.loadingStatus)
    }
  }
}
</script>
<style lang="scss">
:root {
  --black: #{$black};
  --grey-stroke: #{$grey-stroke};
}
</style>
<style lang="sass">
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap&subset=cyrillic,cyrillic-ext,latin-ext')

body
  overflow-x: hidden
  background: #eeeeee
  margin: 0
  font-family: 'Montserrat', sans-serif
  &.modal-open
    overflow-y: hidden
#app
  text-align: center
  font-family: 'Montserrat', sans-serif
  .container
    max-width: rem(960)
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0
input[type='number']
  -moz-appearance: textfield

.tooltip-plate
  display: flex
  justify-content: center
  align-items: center
  padding: rem(50) rem(24)

.code
  position: absolute
  opacity: 0
  pointer-events: none
  transition: .5s
  transform: translateY(-100%)
  width: 100%
  padding: 20px
  background: lime
  left: 0
  right: 0
  margin: auto
  display: block
  input
    width: 80%
    margin: auto
    display: block
  &.active
    transform: translateY(50%)
    opacity: 1
    pointer-events: auto
</style>
