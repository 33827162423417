<template>
  <div id="app">
    <div class="container">
      <div class="row browser__message-overlay">
        <div class="col-12">
          <div class="browser__message">
            <div class="browser__message-title">
              {{ $t('message.notSupport.title') }}
            </div>
            <div class="browser__message-text">
              {{ $t('message.notSupport.text') }}
            </div>
            <div class="browser__message-text">{{ $t('message.notSupport.subtext') }}</div>
            <div class="browser__message-browsers">
              <a href="https://www.google.com/intl/ru/chrome/" target="_blank" class="item">
                <img class="item__img" src="@/assets/img/chrome.svg" alt="chrome" />
                <div class="item__text">Google Chrome</div>
              </a>
              <a href="https://www.mozilla.org/ru/firefox/new/" class="item">
                <img
                  class="item__img"
                  src="@/assets/img/firefox.svg"
                  target="_blank"
                  alt="firefox"
                />
                <div class="item__text">FireFox</div>
              </a>
              <a class="item" href="https://www.opera.com/ru">
                <img class="item__img" src="@/assets/img/opera.svg" target="_blank" alt="opera" />
                <div class="item__text">Opera</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {}
  },
  components: {
    // StoreInfo,
  },
  methods: {},
  mounted() {},
  computed: {}
}
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap&subset=cyrillic,cyrillic-ext,latin-ext')
body
  overflow-x: hidden
  background: #eeeeee
  margin: 0
  font-family: 'Montserrat', sans-serif
#app
  text-align: center
  font-family: 'Montserrat', sans-serif
  .container
    max-width: rem(960)
  .browser
    &__message
      margin: auto
      display: block
      background: $white
      width: rem(500)
      text-align: left
      border-radius: rem(5)
      padding: rem(15)
      max-width: 100%
      box-shadow: 0 0 rem(7) 0 rgba(0, 0, 0, 0.16)
      &-title
        font-size: rem(18)
        font-weight: 700
      &-text
        font-size: rem(14)
        padding: rem(20) 0 0
        font-weight: 500
      &-browsers
        display: flex
        flex-direction: row
        justify-content: space-around
        align-items: center
        padding-top: rem(20)
        .item
          background: $ec
          border-radius: rem(5)
          width: calc(100% / 3.2)
          height: rem(145)
          display: flex
          flex-direction: column
          align-items: center
          justify-content: center
          color: $black
          &__img
            max-width: rem(86)
            height: auto
          &__text
            padding-top: rem(15)
            color: $black
      &-overlay
        height: 100vh
        align-items: center
</style>
