import Vue from 'vue'
import Vuex from 'vuex'
import { cloneDeep } from 'lodash'
import {
  sortMaterialsByAngle,
  getRandomId,
  slopeForms,
  filterFastenersByBaseType,
  addToggleStatusToTables,
  updateSlopeParamsExceptSome,
  updateSectorFixtureCalculationThickness, normalizeResultsData, getObjectId
} from '@/utils'
import { getResultFromDB, getContent, getTrimmedContent, checkTNToken, getResults } from '@/api'
import { sendMetrics } from '@/api/metrics'
import { region } from '@/utils/makeInitialRequest'
import { calcLabel } from '@/utils/sendStatistic'
const pages = {
  wizard: 'wizard',
  result: 'result'
}

export function initStore(plugins) {
  const { i18n, router } = plugins
  const translations = i18n.messages[i18n.locale].message

  Vue.use(Vuex)
  const store = new Vuex.Store({
    state: {
      hash: null,
      TNTokenStatus: false,
      TNToken: null,
      isErrorLink: false,
      category: null,
      /* eslint-disable import/no-unresolved */
      /* eslint-disable global-require */
      userInfo: {
        result: null,
        sessionId: null,
        objectId: null
      },
      saveCalculationLink: null,
      publicLink: null,
      unit: null,
      defaultRoofOptions: {
        base: 'default',
        material: null
      },
      pages: {
        wizard: {
          isLoading: true
        },
        result: {
          isLoading: false
        }
      },
      rowsToPdf: [],
      result: [],
      materialsTypes: [],
      materials: [],
      fasteners: [],
      baseTypes: [],
      defaultBaseTypeId: null,
      slopes: [
        {
          uid: 'evlkk9sd2',
          name: translations.store.sectorName,
          material: '',
          materialSize: {
            width: 0,
            height: 0
          },
          fixtureCalculation: {
            need: true,
            type: null,
            thickness: 0,
            fastener: null
          },
          slopeCount: 1,
          slopeLayersCount: 1,
          slopeType: '',
          slopeForm: 'square',
          slopeParams: [
            {
              width: 4,
              height: 4,
              offsetValley: null
            }
          ],
          unit: null
        }
      ],
      defaultFixtureCalculation: {
        need: true,
        type: null,
        thickness: 0,
        fastener: null
      },
      defaultSlope: {
        uid: null,
        name: translations.store.sectorName,
        material: '',
        materialSize: {
          width: 0,
          height: 0
        },
        fixtureCalculation: {
          need: true,
          type: null,
          thickness: 0,
          fastener: null
        },
        slopeCount: 1,
        slopeLayersCount: 1,
        slopeType: '',
        slopeForm: 'square',
        slopeParams: [
          {
            width: 4,
            height: 4,
            offsetValley: null
          }
        ],
        unit: null
      },
      defaultMountingId: null
      /* eslint-disable import/no-unresolved */
      /* eslint-disable global-require */
    },
    getters: {
      allSlopes(state) {
        return state.slopes
      },
      getSlope: state => id => {
        return state.slopes.length <= id ? state.slopes[0] : state.slopes[id]
      },
      getDefaultSvgParams(state) {
        return state.defaultSvgParams
      },
      allMaterials(state) {
        return state.materials
      },
      allMaterialsTypes(state) {
        return state.materialsTypes
      },
      getMaterial: state => id => {
        return state.materials.findIndex(p => p.id === id)
      },
      getResult(state) {
        return state.result
      },
      getTNToken(state) {
        return state.TNToken
      },
      getRowsToPdf(state) {
        return state.rowsToPdf
      },
      getHash(state) {
        return state.hash
      },
      getUnit(state) {
        return state.unit
      },
      publicCalculationLink: state => state.publicLink
        ? `${state.publicLink}${state.userInfo.result.id}`
        : ``
    },
    mutations: {
      UPDATE_HASH: (state, payload) => {
        state.hash = payload.hash
      },
      UPDATE_TN_TOKEN_STATUS: (state, TNTokenStatus) => {
        state.TNTokenStatus = TNTokenStatus
      },
      UPDATE_TN_TOKEN: (state, token) => {
        state.TNToken = token
      },
      updateSlopeType: (state, payload) => {
        state.slopes[payload.id].slopeType = payload.type
        if (payload.type === 'mainSlope') {
          Vue.set(state.slopes[payload.id], 'slopeParams', [{ ...slopeForms.square }])
          delete state.slopes[payload.id].roofOptions
        } else {
          Vue.set(state.slopes[payload.id], 'slopeParams', [{ ...slopeForms.triangle }])
          Vue.set(state.slopes[payload.id], 'roofOptions', { ...state.defaultRoofOptions })
          state.slopes[payload.id].slopeForm = 'triangle'
        }
      },
      updateSlopeForm: (state, payload) => {
        state.slopes[payload.id].slopeForm = payload.form
        const [oldParams] = state.slopes[payload.id].slopeParams
        Vue.set(state.slopes[payload.id], 'slopeParams', [
          updateSlopeParamsExceptSome(oldParams, { ...slopeForms[payload.form] })
        ])
        state.slopes[payload.id].slopeCount = 1
        state.slopes[payload.id].slopeLayersCount = 1
      },
      UPDATE_ROOF_OPTIONS_PARAM: (state, { id, key, val }) => {
        state.slopes[id].roofOptions[key] = val
      },
      UPDATE_SLOPE_PARAM: (state, { id, key, val }) => {
        state.slopes[id].slopeParams[0][key] = val
      },
      UPDATE_FASTENER_CALCULATION_PARAM: (state, { id, key, val }) => {
        state.slopes[id].fixtureCalculation[key] = val
      },
      updateSlopeWidth: (state, payload) => {
        state.slopes[payload.id].slopeParams[0].width = payload.width
      },
      updateSlopeHeight: (state, payload) => {
        state.slopes[payload.id].slopeParams[0].height = payload.height
      },
      updateSlopeBias1: (state, payload) => {
        state.slopes[payload.id].slopeParams[0].bias1 = payload.bias1
      },
      updateSlopeBias2: (state, payload) => {
        state.slopes[payload.id].slopeParams[0].bias2 = payload.bias2
      },
      updateSlopeBiasHeight: (state, payload) => {
        state.slopes[payload.id].slopeParams[0].biasHeight = payload.biasHeight
      },
      updateSlopeBiasWidth: (state, payload) => {
        state.slopes[payload.id].slopeParams[0].biasWidth = payload.biasWidth
      },
      updateSlopeRatio: (state, payload) => {
        state.slopes[payload.id].slopeParams.ratio = payload.ratio
      },
      updateSlopeRatioNew: (state, payload) => {
        state.slopes[payload.id].slopeParams[0].ratio = payload.ratio
      },
      updateSlopeCount: (state, payload) => {
        state.slopes[payload.id].slopeCount = payload.count
      },
      updateSlopeLayersCount: (state, payload) => {
        state.slopes[payload.id].slopeLayersCount = payload.count
      },
      updateFixtureCalculation: (state, payload) => {
        if (payload.need === false) {
          state.slopes[payload.id].fixtureCalculation.need = payload.need
          delete state.slopes[payload.id].fixtureCalculation.type
          delete state.slopes[payload.id].fixtureCalculation.thickness
        } else {
          Vue.set(
            state.slopes[payload.id],
            'fixtureCalculation',
            cloneDeep(state.defaultFixtureCalculation)
          )
        }
      },
      updateFixtureCalculationType: (state, payload) => {
        state.slopes[payload.id].fixtureCalculation.type = payload.baseTypeId
      },
      updateFixtureCalculationThickness: (state, payload) => {
        state.slopes[payload.id].fixtureCalculation.thickness = payload.val
      },
      updateRoofSlope: (state, payload) => {
        state.slopes[payload.id].slopeParams.roofSlope = payload.val
      },
      updateSlopeMaterial: (state, payload) => {
        Vue.set(state.slopes[payload.id], 'material', cloneDeep(payload.material))
      },
      UPDATE_SLOPE_MATERIAL: (state, { sectorId, materialId }) => {
        state.slopes[sectorId].material = materialId
        const originalMaterial = state.materials.find(m => m.id === materialId)
        const [materialParams] = originalMaterial.params
        state.slopes[sectorId].materialSize.height = materialParams.height
        state.slopes[sectorId].materialSize.width = materialParams.width
        Vue.set(state.slopes[sectorId].materialSize, 'id', materialParams.id)
      },
      updateSlopeMaterialSizes: (state, payload) => {
        Vue.set(state.slopes[payload.id].materialSize, 'width', cloneDeep(payload.sizes.width))
        Vue.set(state.slopes[payload.id].materialSize, 'height', cloneDeep(payload.sizes.height))
        Vue.set(state.slopes[payload.id].materialSize, 'id', cloneDeep(payload.sizes.id))
      },
      updateSlopeName: (state, payload) => {
        state.slopes[payload.id].name = payload.name
      },
      UPDATE_UNIT: (state, payload) => {
        if (payload.index === null) {
          state.unit = payload.unit
          state.slopes.forEach((slope) => { slope.unit = payload.unit })
        } else {
          state.slopes[payload.index].unit = payload.unit
        }
      },
      addNewSlope: (state, payload) => {
        state.defaultSlope.name = `Участок ${payload.id}`
        state.defaultSlope.uid = getRandomId()
        state.slopes.push(cloneDeep(state.defaultSlope))
      },
      replaceAllSlopes(state, payload) {
        state.slopes = payload
      },
      updateMaterialsTypes(state, payload) {
        state.materialsTypes = payload
      },
      updateResult(state, payload) {
        state.result = Object.freeze(payload)
      },
      deleteSlope(state, payload) {
        state.slopes.splice(payload, 1)
      },
      removeSlopeMaterial(state, payload) {
        Vue.set(state.slopes[payload], 'material', cloneDeep(state.defaultSlope.material))
        Vue.set(
          state.slopes[payload].materialSize,
          'width',
          cloneDeep(state.defaultSlope.materialSize.width)
        )
        Vue.set(
          state.slopes[payload].materialSize,
          'height',
          cloneDeep(state.defaultSlope.materialSize.height)
        )
      },
      setSlopeToDefault(state, payload) {
        Vue.set(state.slopes[payload], 'slopeType', cloneDeep(''))
        Vue.set(state.slopes[payload], 'material', cloneDeep(''))
        Vue.set(state.slopes[payload], 'slopeForm', cloneDeep('square'))
        Vue.set(state.slopes[payload].materialSize, 'width', cloneDeep(0))
        Vue.set(state.slopes[payload].materialSize, 'height', cloneDeep(0))
        Vue.set(
          state.slopes[payload],
          'fixtureCalculation',
          cloneDeep(state.defaultSlope.fixtureCalculation)
        )
        Vue.set(state.slopes[payload], 'slopeParams', cloneDeep(state.defaultSlope.slopeParams))
      },
      updateRowsToPdf(state, payload) {
        Vue.set(state, 'rowsToPdf', cloneDeep(payload))
      },
      RESET_SLOPES(state) {
        state.slopes = []
        state.result = []
      },
      UPDATE_CATEGORY(state, payload) {
        state.category = Number(payload)
      },
      MASS_UPDATE_MATERIAL_IN_SECTORS: (state, { sectors, newMaterial, newThickness }) => {
        const originalMaterial = state.materials.find(m => m.id === newMaterial)
        const [materialParams] = originalMaterial.params
        const isNeedToUpdateThickness = !!newThickness || Number.isInteger(newThickness)
        state.slopes = state.slopes.map(s => {
          return {
            ...s,
            // prettier-ignore
            fixtureCalculation: sectors.includes(s.uid)
              ? updateSectorFixtureCalculationThickness(
                isNeedToUpdateThickness,
                s.fixtureCalculation,
                newThickness
              )
              : s.fixtureCalculation,
            material: sectors.includes(s.uid) ? newMaterial : s.material,
            materialSize: {
              height: sectors.includes(s.uid) ? materialParams.height : s.materialSize.height,
              width: sectors.includes(s.uid) ? materialParams.width : s.materialSize.width,
              id: sectors.includes(s.uid) ? materialParams.id : s.materialSize.id
            }
          }
        })
      },
      UPDATE_PAGE_LOADING_STATUS: (state, { page, status }) => {
        state.pages[page].isLoading = status
      },
      UPDATE_CONTENT: (state, { baseTypes, categories, defaultBaseTypeId, fasteners, products }) => {
        const sortedMaterials = sortMaterialsByAngle(products)
        Vue.set(state, 'materials', sortedMaterials)
        const [firstMaterial] = sortedMaterials
        state.defaultRoofOptions.material = firstMaterial.id
        state.fasteners = fasteners
        const suitableFasteners = filterFastenersByBaseType(fasteners, defaultBaseTypeId)
        const firstFastener =
          suitableFasteners.find((item) => item.id === state.defaultMountingId) ||
          suitableFasteners[0]
        state.defaultSlope.fixtureCalculation.fastener = firstFastener.id
        state.defaultFixtureCalculation.fastener = firstFastener.id
        const [firstSector] = state.slopes
        if (firstSector.fixtureCalculation.fastener === null) {
          firstSector.fixtureCalculation.fastener = firstFastener.id
        }
        if (firstSector.fixtureCalculation.type === null) {
          firstSector.fixtureCalculation.type = defaultBaseTypeId
        }
        state.defaultSlope.fixtureCalculation.type = defaultBaseTypeId
        state.defaultFixtureCalculation.type = defaultBaseTypeId
        state.materialsTypes = categories
        state.baseTypes = baseTypes
        state.defaultBaseTypeId = defaultBaseTypeId
      },
      UPDATE_SECTOR_RESULTS_TABLE_EXPAND_STATUS: (state, { sectorIndex, table, status }) => {
        const clonedResult = cloneDeep(state.result)
        clonedResult.sections[sectorIndex][table].isExpanded = status
        state.result = Object.freeze(clonedResult)
      },
      UPDATE_CALCULATION_RESULT(state, payload) {
        state.userInfo.result = payload
      },
      UPDATE_CALCULATION_RESULT_ID(state, payload) {
        if (state.userInfo.result === null) {
          state.userInfo.result = {}
        }
        state.userInfo.result.id = payload
      },
      UPDATE_SESSION_ID(state, payload) {
        state.userInfo.sessionId = payload
      },
      UPDATE_OBJECT_ID(state, payload) {
        state.userInfo.objectId = payload
      },
      UPDATE_CALCULATION_STATUS(state, payload) {
        state.saveCalculationLink = payload
      },
      RESET_UNIT(state, payload) {
        if (payload.index === null) {
          state.unit = null
        } else {
          state.slopes[payload.index].unit = null
        }
      },
      UPDATE_DEFAULT_MOUNTING_ID(state, payload) {
        state.defaultMountingId = payload
      },
      ADD_PUBLIC_LINK(state, payload) {
        state.publicLink = payload
      }
    },
    actions: {
      resetUserInfoResults({ commit }) {
        commit('UPDATE_CALCULATION_RESULT', null)
      },
      updateBasementAndFastener({ state, commit }, { id, baseTypeId }) {
        commit('updateFixtureCalculationType', {
          id,
          baseTypeId
        })
        const suitableFasteners = filterFastenersByBaseType(state.fasteners, baseTypeId)
        const [firstFastener] = suitableFasteners
        const stateFastenerId = state.slopes[id].fixtureCalculation.fastener
        if (!suitableFasteners.some(fastener => fastener.id === stateFastenerId)) {
          commit('UPDATE_FASTENER_CALCULATION_PARAM', {
            id,
            key: 'fastener',
            val: firstFastener.id
          })
        }
      },
      startNewCalculation({ commit }) {
        commit('RESET_SLOPES')
        commit('addNewSlope', { id: 1 })
        commit('UPDATE_CALCULATION_RESULT', null)
        commit('UPDATE_CALCULATION_STATUS', null)
        router.push(`/`)
      },
      fetchMaterials({ commit }) {
        getContent(i18n.locale, region).then(response => {
          commit('UPDATE_DEFAULT_MOUNTING_ID', response.data?.defaultMountingId)
          commit('UPDATE_CONTENT', response.data)
          commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.wizard, status: false })
        })
      },
      addSessionId({ commit }, sessionId) {
        commit('UPDATE_SESSION_ID', sessionId)
      },
      addObjectId({ commit }, ObjectId) {
        commit('UPDATE_OBJECT_ID', ObjectId)
      },
      markCalculationAsSaved({ commit }, flag) {
        commit('UPDATE_CALCULATION_STATUS', flag)
      },
      resetUnit({ commit }, payload) {
        commit('RESET_UNIT', payload)
      },
      addPublicLink({ commit }, privateLink) {
        const url = new URL(privateLink)
        commit('ADD_PUBLIC_LINK', `${url.origin}/calculators/${calcLabel}/?object=`)
      },
      getTotalResults({ state, commit }) {
        commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: true })
        commit('UPDATE_OBJECT_ID', getObjectId())

        let link, id
        if (state.userInfo?.result) {
          link = state.userInfo.result.link
          id = state.userInfo.result.id
        }
        const forProfileData = (link || id) ? { link, id } : null
        const localizatedUnits = i18n.messages[i18n.locale].message.units

        const slopes = state.slopes.map((slope) => {
          if (slope.unit === null) {
            delete slope.unit
          }

          return slope
        })

        getResults(
          slopes,
          i18n.locale,
          region,
          forProfileData,
          state.userInfo.objectId,
          state.unit
        ).then((response) => {
          const normalizedResultsData = normalizeResultsData(response.data, localizatedUnits)
          commit('updateResult', addToggleStatusToTables(normalizedResultsData))
          if (!forProfileData) commit('UPDATE_CALCULATION_RESULT', response.data?.result)
          commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: false })
        })
      },
      requestSavedResult({ commit }, { urlQuery }) {
        commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: true })
        commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.wizard, status: false })
        commit('UPDATE_CALCULATION_RESULT_ID', urlQuery)

        Promise.all([getContent(i18n.locale, region), getResultFromDB(urlQuery)])
          .then(response => {
            const [content, savedResults] = response
            if (savedResults.data.category) {
              const category = Number(savedResults.data.category)
              const isCategoryValid = content.data.categories.some(cat => cat.id === category)
              if (isCategoryValid) {
                sessionStorage.setItem('category', category)
                content.data.products = content.data.products.filter(
                  product => product.type === category
                )
                content.data.categories = content.data.categories.filter(cat => cat.id === category)
                commit('UPDATE_CATEGORY', category)
              }
            }
            commit('UPDATE_DEFAULT_MOUNTING_ID', content.data?.defaultMountingId)
            commit('UPDATE_CONTENT', content.data)

            const normalizedResultsData = normalizeResultsData(
              JSON.parse(savedResults.data.result),
              i18n.messages[i18n.locale].message.units
            )

            let commonUnit = null
            if (savedResults.data?.unit) {
              commonUnit = savedResults.data?.unit
            } else {
              const units = new Set(JSON.parse(savedResults.data.data).map(slope => slope.unit))
              commonUnit = units.size === 1 ? [...units][0] : null
            }

            commit('replaceAllSlopes', JSON.parse(savedResults.data.data))
            commit('updateResult', addToggleStatusToTables(normalizedResultsData))
            commit('UPDATE_UNIT', { index: null, unit: commonUnit })
            commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: false })
          })
          .catch(() => {
            router.push({ name: '404' })
          })
      },
      fetchMaterialsMain({ commit }, category) {
        getTrimmedContent(category, i18n.locale, region).then(response => {
          commit('UPDATE_CATEGORY', category)
          commit('UPDATE_DEFAULT_MOUNTING_ID', response.data?.defaultMountingId)
          commit('UPDATE_CONTENT', response.data)
        })
      },
      updateTNToken({ commit }) {
        commit('UPDATE_TN_TOKEN_STATUS', true)
      },
      checkTNToken({ commit }, token) {
        checkTNToken(token, i18n.locale, region)
          .then(() => {
            commit('UPDATE_TN_TOKEN', token)
          })
          .catch(() => {
            commit('UPDATE_TN_TOKEN_STATUS', true)
            console.log('Невалидный ключ')
          })
      },
      async sendMetrics({ state }, { stepNumber, sessionToken }) {
        const { userInfo, slopes, result, hash } = state
        try {
          await sendMetrics(sessionToken, stepNumber, userInfo, slopes, result, region, i18n.locale, hash)
        } catch (e) {}
      }
    }
  })

  return Promise.resolve({
    ...plugins,
    store
  })
}
