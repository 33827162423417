import axios from 'axios'
import { cloneDeep } from 'lodash'
import { detailsTables } from '@/utils'
import { defaultColor } from '@/utils/makeInitialRequest'

const color = localStorage.getItem('main-color') ?? defaultColor

export const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_API_URL
      : `${location.origin}/service_api/`,
  headers: {
    'Content-Type': 'application/hal+json',
    Accept: 'application/json'
  }
})
export const actionApi = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development' ? process.env.VUE_APP_ACTION_URL : `${location.origin}`,
  headers: {
    'Content-Type': 'application/hal+json',
    Accept: 'application/json'
  }
})
const ratingEndpoint = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_RATING_URL
      : `${location.origin}/service_rating/`,
  headers: {
    'Content-Type': 'application/hal+json',
    Accept: 'application/json'
  }
})

export const getPdfTotal = (
  slopesCount,
  countWord,
  square,
  volume,
  materials,
  albums,
  fasteners,
  sectors,
  lang,
  region,
  resultId,
  publicCalculationLink
) => {
  let formData = new FormData()
  formData.append('type', 'pdf_total')
  const postDataFront = getPdfFirstPage()
  if (publicCalculationLink) {
    postDataFront.link = publicCalculationLink
  }
  const postDataHeader = {
    count: slopesCount,
    countWord,
    square,
    volume
  }
  const postData = {
    header: postDataHeader,
    firstPage: postDataFront,
    materials,
    albums,
    fasteners,
    sectors,
    color
  }
  formData.append('data', JSON.stringify(postData))
  formData.append('lang', lang)
  formData.append('region', region)
  if (resultId) formData.append('result', resultId)
  return api.post('/pdf', formData, {
    responseType: 'blob'
  })
}

export const getPdfSector = (
  image,
  postDataHeader,
  groups,
  fasteners,
  material,
  albums,
  fixtureCalculationNeed,
  materialsTitle,
  fastenersTitle,
  lang,
  region,
  resultId,
  publicCalculationLink
) => {
  let formData = new FormData()
  formData.append('type', 'pdf_section')
  formData.append('image', image)
  const postDataFront = getPdfFirstPage()
  if (publicCalculationLink) {
    postDataFront.link = publicCalculationLink
  }
  const postData = {
    header: postDataHeader,
    firstPage: postDataFront,
    groups,
    fasteners,
    material,
    albums,
    fixtureCalculationNeed,
    materialsTitle,
    fastenersTitle,
    color
  }
  formData.append('data', JSON.stringify(postData))
  formData.append('lang', lang)
  formData.append('region', region)
  if (resultId) formData.append('result', resultId)
  return api.post('/pdf', formData, {
    responseType: 'blob'
  })
}

export const getXlsTotal = (materials, fasteners, page, user, header, lang, region, resultId) => {
  let formData = new FormData()
  const data = {
    materials,
    fasteners,
    page
  }
  formData.append('data', JSON.stringify(data))
  formData.append('info', JSON.stringify(user))
  formData.append('header', JSON.stringify(header))
  formData.append('lang', lang)
  formData.append('region', region)
  if (resultId) formData.append('result', resultId)
  return api.post('/excel', formData, {
    responseType: 'blob'
  })
}
export const getXlsSector = (section, thickness, page, user, header, lang, region, resultId) => {
  let clonedSection = cloneDeep(section)
  clonedSection = {
    ...clonedSection,
    [detailsTables.computation]: [...clonedSection[detailsTables.computation].data],
    [detailsTables.fasteners]: [...clonedSection[detailsTables.fasteners].data],
    [detailsTables.fasteners_group]: [...clonedSection[detailsTables.fasteners_group].data]
  }
  let formData = new FormData()
  let data = {
    section: clonedSection,
    page,
    thickness
  }
  formData.append('data', JSON.stringify(data))
  formData.append('info', JSON.stringify(user))
  formData.append('header', JSON.stringify(header))
  formData.append('lang', lang)
  formData.append('region', region)
  if (resultId) formData.append('result', resultId)
  return api.post('/excel', formData, {
    responseType: 'blob'
  })
}

export const getContent = (lang, region) => {
  return api.get(`/get_content`, {
    params: {
      lang: lang,
      region: region
    }
  })
}

export const getTrimmedContent = (category, lang, region) => {
  return api.get('/get_content', {
    params: {
      category,
      lang
    }
  })
}

export const getResultFromDB = urlQuery => {
  const hash = new FormData()
  hash.append('data', urlQuery)
  return api.post('/results_get', hash)
}

export const getResults = (slopes, lang, region, result = null, objectId = null, unit) => {
  let formData = new FormData()
  formData.append('lang', lang)
  formData.append('region', region)
  formData.append('data', JSON.stringify(slopes))
  if (objectId) formData.append('objectId', JSON.stringify(objectId))
  if (result) formData.append('result', JSON.stringify(result))
  if (unit) formData.append('unit', unit)
  return api.post('/calculate', formData)
}

const getPdfFirstPage = () => {
  return {
    year: new Date().getFullYear()
  }
}

export const sendRating = (object, lang, region, hash) => {
  let formData = new FormData()
  formData.append('data', JSON.stringify(object))
  formData.append('lang', lang)
  formData.append('region', region)
  if (hash) formData.append('hash', hash)

  return ratingEndpoint.post('/vote', formData)
}

export const checkTNToken = (token, lang, region) => {
  let formData = new FormData()
  formData.append('token', token)
  formData.append('lang', lang)
  formData.append('region', region)

  return api.post('/token_status', formData)
}
