import { cloneDeep } from 'lodash'

/**
 * Ограничение числа в диапазоне
 * @param num Исходное число
 * @param min Граничное значение снизу
 * @param max Граничное значение сверху
 */
export const clamp = (num, min = -Infinity, max = Infinity) =>
  Math.min(Math.max(Number(num), min), max)

export const isValidNumber = (number, canBeZero = false) => {
  if (canBeZero) {
    return !isNaN(parseFloat(number)) && number >= 0
  } else {
    return !isNaN(parseFloat(number)) && number > 0
  }
}

export const isValidInteger = (number, canBeZero = false) => {
  if (!Number.isInteger(number)) {
    return false
  } else {
    if (canBeZero) {
      return !isNaN(parseFloat(number)) && number >= 0
    } else {
      return !isNaN(parseFloat(number)) && number > 0
    }
  }
}

export const scrollIntoView = target => {
  target.scrollIntoView({ block: 'start', behavior: 'smooth' })
}

export const getMin = (...args) => Math.min(...args)

export const roofOptionsBasements = [
  {
    id: 0,
    name: 'Уклон задан конструкцией',
    base: 'default'
  },
  {
    id: 1,
    name: 'Уклон задан клиновидной теплоизоляцией',
    base: 'custom'
  }
]

export const ratios = [
  {
    id: 0,
    value: 1,
    text: '1 к 1'
  },
  {
    id: 1,
    value: 1.5,
    text: '1 к 1.5'
  },
  {
    id: 2,
    value: 2,
    text: '1 к 2'
  },
  {
    id: 3,
    value: 2.5,
    max: 1.6,
    min: 0,
    text: '1 к 2.5'
  },
  {
    id: 4,
    value: 3,
    max: 1.9,
    min: 1.6,
    text: '1 к 3'
  },
  {
    id: 5,
    value: 3.5,
    max: 2.1,
    min: 1.9,
    text: '1 к 3.5'
  },
  {
    id: 6,
    value: 4,
    max: 2.4,
    min: 2.1,
    text: '1 к 4'
  },
  {
    id: 7,
    value: 4.5,
    max: 2.6,
    min: 2.4,
    text: '1 к 4.5'
  },
  {
    id: 8,
    value: 5,
    max: 3.1,
    min: 2.6,
    text: '1 к 5'
  },
  {
    id: 9,
    value: 6,
    max: 21,
    min: 3.1,
    text: '1 к 6'
  }
]

export const sortMaterialsByAngle = materials => {
  return cloneDeep(materials).sort((a, b) => {
    return a.angle - b.angle
  })
}

export const getRandomId = () => Math.random().toString(36).substr(2, 9)

export const slopeTypes = {
  mainSlope: 'mainSlope',
  contrSlope: 'contrSlope'
}

export const slopeTypesName = {
  mainSlope: 'Основной уклон',
  contrSlope: 'Контруклон'
}

export const getFormattedWord = (words, count) => {
  const cases = [2, 0, 1, 1, 1, 2]
  const currentWord =
    count % 100 > 4 && count % 100 < 20 ? 2 : cases[count % 10 < 5 ? count % 10 : 5]
  return words[currentWord]
}

export const isResultPageAllowed = sectors => sectors.every(s => s.materialSize.width !== 0)

export const slopeForms = {
  square: {
    width: 4,
    height: 4
  },
  trapezoid: {
    width: 6,
    height: 4,
    bias1: 0.5,
    bias2: 0.5
  },
  reverseTrapezoid: {
    width: 6,
    height: 4,
    bias1: 0.5,
    bias2: 0.5
  },
  cornerLeftTop: {
    width: 2,
    height: 1,
    biasHeight: 1,
    biasWidth: 1
  },
  cornerRightTop: {
    width: 2,
    height: 2,
    biasHeight: 1,
    biasWidth: 1
  },
  cornerLeftBottom: {
    width: 2,
    height: 1,
    biasHeight: 1,
    biasWidth: 1
  },
  cornerRightBottom: {
    width: 2,
    height: 2,
    biasHeight: 1,
    biasWidth: 1
  },
  triangle: {
    width: 3,
    ratio: 3,
    offsetValley: 0,
    roofSlope: 1.7
  },
  doubleSideTriangleHorizontal: {
    width: 3,
    ratio: 3,
    roofSlope: 1.7
  },
  doubleSideTriangleVertical: {
    width: 3,
    ratio: 3,
    offsetValley: 0,
    roofSlope: 1.7
  },
  rhombus: {
    width: 3,
    ratio: 3,
    roofSlope: 1.7
  },
  rectangularTrapezoidVertical: {
    width: 3,
    ratio: 3,
    bias1: 0.5,
    offsetValley: 0,
    roofSlope: 1.7
  },
  rectangularTrapezoidHorizontal: {
    width: 3,
    ratio: 3,
    bias1: 0.5,
    offsetValley: 0,
    roofSlope: 1.7
  },
  doubleSideRectangularTrapezoidVertical: {
    width: 3,
    ratio: 3,
    bias1: 0.5,
    offsetValley: 0,
    roofSlope: 1.7
  }
}

export const updateSectorFixtureCalculationThickness = (
  isNeedToUpdateThickness,
  fixtureCalculation,
  newThickness
) => {
  return isNeedToUpdateThickness
    ? setNewThickness(fixtureCalculation, newThickness)
    : fixtureCalculation
}

const setNewThickness = (fixtureCalculation, newThickness) => {
  // prettier-ignore
  return Number.isInteger(fixtureCalculation?.thickness)
    ? {
      ...fixtureCalculation,
      [thickness]: newThickness
    } : { ...fixtureCalculation }
}

const roofSlope = 'roofSlope'
const thickness = 'thickness'
const ratio = 'ratio'

export const updateSlopeParamsExceptSome = (oldParams, newParams) => {
  const { newRatio, newRoofSlope, ...etc } = newParams
  const oldRatio = oldParams?.ratio
  const oldRoofSlope = oldParams?.roofSlope
  return oldRoofSlope && oldRatio
    ? { ...etc, [roofSlope]: oldRoofSlope, [ratio]: oldRatio }
    : newParams
  // return Object.fromEntries(Object.entries(newParams).filter(([key, _]) => key !== roofSlope))
}

export const filterFastenersByBaseType = (fasteners, baseType) => {
  return fasteners.filter(fastener => fastener.baseTypeId.includes(baseType))
}

export const filterMaterialsBySlopeType = (materials, slopeType) => {
  return materials.filter(material => material.slope === slopeType)
}

export const filterMaterialsForMainSlope = materials => {
  return materials.filter(
    material => material.slope === slopeTypes.mainSlope || material.isShowOnMainSlope
  )
}

export const getCurrentBasement = (isNeed, baseTypes, currentType) => {
  return isNeed ? baseTypes.find(baseType => baseType.id === currentType).name : null
}

const dash = '—'

const setFastenerVal = (val, units) => (Number(val) > 0 ? `${val} ${units.mm}` : units?.alternative?.poppetElement)

const setAnkVal = (val, units) => (!val ? dash : `${val} ${units.mm}`)

export const normalizeResultsData = (results, units) => {
  let { rowsHeight = [], plateLayouts = [], ...rest } = results

  if (!rowsHeight.length) {
    rowsHeight = results.sections.map((sector) => sector?.rowsHeight)
  }

  if (!plateLayouts.length) {
    plateLayouts = results.sections.map((sector) => sector?.plateLayout)
  }

  return {
    ...rest,
    total: results.total.map(item => {
      return {
        ...item,
        countUnit: units[item.countUnit] ?? ''
      }
    }),
    sections: results.sections.map((sector, index) => {
      return {
        ...sector,
        rowsHeight: rowsHeight[index] ?? null,
        plateLayout: plateLayouts[index],
        computation: checkField(sector.computation).map(item => {
          return {
            ...item,
            countUnit: units[item.countUnit] ?? '',
            countPackageUnit: units[item.countPackageUnit] ?? ''
          }
        }),
        fasteners_group: checkField(sector.fasteners_group).map(item => {
          return {
            ...item,
            fastener: setFastenerVal(item.fastener, units),
            ank: setAnkVal(item.ank, units)
          }
        })
      }
    })
  }
}

function checkField(field) {
  return Array.isArray(field) ? field : field.data
}

export const detailsTables = {
  computation: 'computation',
  fasteners: 'fasteners',
  fasteners_group: 'fasteners_group'
}

const setTableToggleStatus = tableDta => {
  return Array.isArray(tableDta) ? { data: [...tableDta], isExpanded: true } : tableDta
}

export const addToggleStatusToTables = results => {
  return {
    ...results,
    sections: results.sections.map(sector => {
      return {
        ...sector,
        [detailsTables.computation]: setTableToggleStatus(sector[detailsTables.computation]),
        [detailsTables.fasteners]: setTableToggleStatus(sector[detailsTables.fasteners]),
        [detailsTables.fasteners_group]: setTableToggleStatus(sector[detailsTables.fasteners_group])
      }
    })
  }
}

export const getPdfTableTitle = (translate, count, defaultTitle, isTableToggled) => {
  const words = translate.sectors
  const word = getFormattedWord(words, count)
  const multipleTitle = isTableToggled
    ? `${defaultTitle} на ${count} ${word}`
    : `${defaultTitle} на 1 участок / ${count} участков`
  return count === 1 ? defaultTitle : multipleTitle
}

export function getObjectId() {
  const objId = localStorage.getItem('objId')

  if (!objId || objId === 'undefined') return null

  const hasDouble = objId.split(',').length > 1
  localStorage.removeItem('objId')

  return hasDouble ? objId.split(',')[0] : objId
}
