export const sendStatistic = label => window.dataLayer.push({ 'event': label })

export const calcLabel = 'wedge-insulating'

export const statisticLabels = {
  prefooter: {
    pdf: `calc-${calcLabel}-pdf`,
    xls: `calc-${calcLabel}-xls`,
    print: `calc-${calcLabel}-print`
  },
  steps: {
    step1: `calc-${calcLabel}-step1`,
    step2: `calc-${calcLabel}-step2`,
    step3: `calc-${calcLabel}-step3`,
    result: `calc-${calcLabel}-final`
  }
}
export const steps = Object.keys(statisticLabels.steps)
