import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { region } from '@/utils/makeInitialRequest'

export const fallbackLocale = 'ru'
export const locale = window.location.pathname.split('/').filter(i => i !== '')[1] ?? fallbackLocale

export function initI18n(data) {
  const { content } = data
  const languages = Object.keys(content?.languages[region ?? locale]?.languages)

  localStorage.setItem('regions', JSON.stringify(Object.keys(content.languages).join(',')))
  localStorage.setItem('languages', JSON.stringify(languages))

  const isLanguageSupport = languages ? languages.includes(locale) : false

  Vue.use(VueI18n)

  return Promise.resolve({
    i18n: new VueI18n({
      locale,
      fallbackLocale,
      messages: {
        [isLanguageSupport ? locale : fallbackLocale]: content.locales
      }
    })
  })
}
