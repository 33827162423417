import Vue from 'vue'
import { initI18n } from '@/plugins/i18n'
import { makeInitialRequest } from '@/utils/makeInitialRequest'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueTippy, { TippyComponent } from 'vue-tippy'
import Notifications from 'vue-notification'
import Vuelidate from 'vuelidate'
import UnsupportBrowsers from './UnsupportBrowsers.vue'
import App from './App.vue'
import { initRouter } from '@/router'
import { initStore } from '@/store'

import 'swiper/dist/css/swiper.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'tippy.js/themes/light.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import { encodeSVG, transparentFavIcon } from '@/utils/utils'

Vue.use(VueTippy)
Vue.component('tippy', TippyComponent)
Vue.use(VueAwesomeSwiper)
Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.config.productionTip = false

// Internet Explorer 6-11
const isIE = !!document.documentMode
// Edge 20+
const isEdge = !isIE && !!window.StyleMedia

makeInitialRequest().then((data) => {
  initI18n(data).then(
    (i18n) => initRouter(i18n).then(
      (i18nAndRouter) => initStore(i18nAndRouter).then(
        (i18nAndRouterAndStore) => {
          const { i18n, router, store } = i18nAndRouterAndStore

          if (!isIE && !isEdge) {
            const vm = new Vue({
              i18n,
              router,
              store,
              render: h => h(App),
              methods: {
                onBodyScroll() {
                  document.body.classList.remove('modal-open')
                },
                offBodyScroll() {
                  window.parent.postMessage({ scrollTop: true }, '*')
                  document.body.classList.add('modal-open')
                }
              },
              mounted() {
                const observer = new ResizeObserver(entries => {
                  entries.forEach(entry => {
                    window.parent.postMessage({ height: entry.contentRect.height }, '*')
                  })
                })
                observer.observe(document.body)
                window.addEventListener('beforeunload', () => {
                  observer.disconnect()
                })
                document.documentElement.style.setProperty('--main-color', localStorage.getItem('main-color'))

                document.title = i18n.messages[i18n.locale].message.home.tabTitle ?? 'ТЕХНОНИКОЛЬ | Калькулятор уклонов крыши'
                const favIconTag = document.querySelector(`[rel='icon']`)

                if (i18n.messages[i18n.locale].message?.home.favIcon) {
                  const encodedSVG = encodeSVG(i18n.messages[i18n.locale].message?.home.favIcon)
                  favIconTag.setAttribute('href', `data:image/svg+xml, ${encodedSVG}`)
                } else {
                  favIconTag.setAttribute('href', `data:image/svg+xml, ${transparentFavIcon}`)
                }
              }

            }).$mount('#app')
            Vue.directive('click-outside', {
              bind(el, binding) {
                el.addEventListener('click', e => e.stopPropagation())
                document.body.addEventListener('click', binding.value)
              },
              unbind(el, binding) {
                document.body.removeEventListener('click', binding.value)
              }
            })

            return vm
          } else {
            new Vue({
              render: h => h(UnsupportBrowsers),
              methods: {},
              mounted() {}
            }).$mount('#app')
          }
        }
      )
    )
  )
})
