import Vue from 'vue'
import Router from 'vue-router'
import { fallbackLocale } from '@/plugins/i18n'
import { sendStatistic, statisticLabels } from '@/utils/sendStatistic'
import { region } from '@/utils/makeInitialRequest'

export function initRouter(plugin) {
  const { i18n } = plugin

  Vue.use(Router)

  const router = new Router({
    mode: 'history',
    routes: [
      {
        path: '/',
        redirect: `/${region}/${i18n.locale}`
      },
      {
        path: '/:region',
        redirect: `${region}/${i18n.locale}`
      },
      {
        path: '/:region/:lang',
        component: {
          render(h) {
            return h('router-view')
          }
        },
        beforeEnter(to, from, next) {
          if (to.path.split('/').reverse()[0] === '' && !window.location.search) {
            next({ path: getRedirectURL() })
          }
          next()
        },
        redirect: getRedirectURL(),
        children: [
          {
            path: 'wizard/:id',
            name: 'Wizard',
            component: () => import('@/components/Wizard.vue')
          },
          {
            path: 'details/:id',
            name: 'Details',
            component: () => import('@/views/Details.vue')
          },
          {
            path: 'result',
            name: 'Result',
            component: () => import('@/views/Result.vue'),
            beforeEnter: (to, from, next) => {
              sendStatistic(statisticLabels.steps.result)
              next()
            }
          },
          {
            path: '404',
            name: '404',
            component: () => import('@/views/404.vue')
          },
          {
            path: '*',
            name: 'MissingPage',
            component: () => import('@/views/404.vue')
          }
        ]
      }
    ],
    scrollBehavior() {
      return { x: 0, y: 0 }
    }
  })

  router.beforeEach((to, from, next) => {
    const fallbackRegion = 'ru'
    const isLanguageSupported = JSON.parse(localStorage.getItem('languages'))
      .includes(to.params.lang)

    const isRegionSupported = JSON.parse(localStorage.getItem('regions'))
      .includes(to.params.region)

    if (!isLanguageSupported) {
      i18n.locale = fallbackLocale
    }

    !to.path.includes(`/${region}/${i18n.locale}`)
      ? next({ path: `/${isRegionSupported ? region : fallbackRegion}/${i18n.locale}${to.path}` })
      : next()
    window.parent.postMessage({ scrollTop: true }, '*')
  })

  function getRedirectURL() {
    const isResult = window.location.pathname.split('/').includes('result')
    const is404 = window.location.pathname.split('/').includes('404')
    const search = window.location.search

    if (isResult && search) {
      return `/${region}/${i18n.locale}/result/${search}`
    }

    if (is404) {
      return `/${region}/${i18n.locale}/404`
    }
    return `/${region}/${i18n.locale}/wizard/0`
  }

  return Promise.resolve({
    ...plugin,
    router
  })
}
